<template>
  <div :class="['home', transitionClass]">
    <Title />
    <slider @transit="transition" />

    <logo />
    <copyright />
  </div>
</template>

<script>
import Title from '@/components/home/Title.vue'
import Slider from '@/components/home/slider/index.vue'
import Copyright from '@/components/general/Copyright'
import Logo from '@/components/general/Logo'

export default {
  name: 'Home',
  components: {
    Logo,
    Copyright,
    Title,
    Slider
  },
  data () {
    return {
      transitionClass: ''
    }
  },
  methods: {
    transition (id) {
      this.$router.push({
        name: 'Detail',
        params: {
          id
        }
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.transitionClass = 'home--transition'

    setTimeout(() => {
      next()
    }, 600)
  }
}
</script>

<style lang="scss" scoped="scoped">
  .home {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    opacity: 1;
    transition: 600ms;
    animation: transition-in 600ms;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    &--transition {
      opacity: 0;
      transform: translateY(50%);
    }
  }

  @keyframes transition-in {
    from {
      opacity: 0;
      transform: translateY(-50%) scale(0.9);
    }

    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
</style>
