<template>
  <div class="logo">
    <img src="@/assets/logo.svg" alt="">
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="scss" scoped="scoped">
.logo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 60px;

  img {
    height: 30px;
  }

  @media (max-width: 900px) {
    display: none;
  }
}
</style>
