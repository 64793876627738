<template>
  <div :class="['next-slide', { 'next-slide--open' : active }, { 'next-slide--hidden' : hidden }]" @click="activate">
    <div class="next-slide-text">
      <img src="@/assets/icons/arrow-right.svg" alt="">
      <p>next item</p>
    </div>
    <div class="next-slide-background" :style="'background-image: url(' + url + ')'"></div>
  </div>
</template>

<script>
export default {
  name: 'NextSlide',
  props: {
    url: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      active: false,
      hidden: false
    }
  },
  watch: {
    index () {
      this.reset()
    }
  },
  methods: {
    activate () {
      if (!this.hidden && !this.active) {
        this.$emit('change')
        this.active = true
      }
    },
    reset () {
      this.active = false
      this.hidden = true

      setTimeout(() => {
        this.show()
      }, 600)
    },
    show () {
      this.hidden = false
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.next-slide {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  background: black;
  color: white;
  height: 160px;
  width: 220px;
  transition: 0.6s;
  overflow: hidden;
  transform: translateY(0);

  @media (max-width: 500px) {
    height: 120px;
    width: 180px;
  }

  &:not(.next-slide--open) {
    &:hover {
      height: 280px;
      width: 360px;

      @media (max-width: 500px) {
        height: 180px;
        width: 260px;
      }

      .next-slide-text {
        padding: 60px;

        @media (max-width: 500px) {
          padding: 30px;
        }
      }

      .next-slide-background {
        opacity: 1;
        transform: scale(1.4) rotate(0deg);
      }
    }
  }

  &-text {
    position: relative;
    z-index: 1;
    font-weight: bold;
    padding: 30px;
    margin: 0;
    transition: 0.4s;
  }

  &-background {
    position: absolute;
    background-size: cover;
    background-position: center;
    top: 0;
    height: 150%;
    width: 150%;
    opacity: 0;
    transition: 0.6s;
    transform: rotate(20deg) translateX(50px);
  }

  &--open {
    height: calc(100% - 120px);
    width: calc(60% - 120px);
    margin: 60px;

    @media (max-width: 900px) {
      height: calc(100% - 120px);
      width: calc(100% - 120px);
    }

    @media (max-width: 500px) {
      margin: 30px;
      height: calc(100% - 60px);
      width: calc(100% - 60px);
    }

    .next-slide-text {
      opacity: 0;
    }

    .next-slide-background {
      opacity: 1;
      transform: scale(1) rotate(0deg) translateX(0);
      width: 100%;
      height: 100%;
    }
  }

  &--hidden {
    transition: none;
    opacity: 0;
    transform: translateY(40%);
  }
}
</style>
