<template>
  <div :class="['slider-item', classes, openClass ]" :style="'background-image: url(' + slide.cover + ')'" @click="open">
    <div class="slider-item-overlay"></div>
    <div class="slider-item-inner inner">
      <div class="inner-index slider-item-transition">
        {{ index + 1 }} / {{ size }}
      </div>
      <div class="inner-action slider-item-transition">
        <p>click</p>
        <img src="@/assets/icons/arrow-action.svg" alt="">
      </div>
      <div class="inner-content">
        <h2 class="inner-content-title inner-content-item slider-item-transition">{{ slide.title }}</h2>
        <p class="inner-content-meta inner-content-item slider-item-transition">{{ formattedDate }}
          <template v-if="slide.categories.length > 0">
            -
            <span v-for="(category, index) in slide.categories" :key="category">
              <template v-if="index > 0">,</template>
              {{ category }}
            </span>
          </template>
        </p>
        <p class="inner-content-description inner-content-item slider-item-transition">
          {{ slide.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderItem',
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: String,
      default: ''
    },
    changing: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      openClass: ''
    }
  },
  computed: {
    formattedDate () {
      const date = []

      date.push(this.slide.date.getDate())
      date.push(this.slide.date.getMonth() + 1)
      date.push(this.slide.date.getFullYear())

      return date.map(item => item > 9 ? item : `0${item}`).join('.')
    }
  },
  methods: {
    async open () {
      this.$emit('transit', this.slide.title.toLowerCase().replaceAll(' ', '-'))
      this.openClass = 'slider-item--change'
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
  .slider-item {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    transition: none;
    transform: translateY(0);
    cursor: pointer;
    animation: transition-in 600ms;

    &-overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      transition: 0.6s;
      opacity: 0;
      background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 40%, rgba(255,255,255,0) 100%);
    }

    &-inner {
      height: 100%;
      width: 100%;
    }

    &-transition {
      transition: 0.4s;
      opacity: 0;
      transform: translateY(60%);
    }

    .inner {
      z-index: 1;
      position: relative;
      padding: 60px;
      color: #fff;
      display: grid;
      grid-template-areas:
        "index action"
        "content content";
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(255,255,255,0) 100%);
      transition: 0.6s;

      @media (max-width: 500px) {
        padding: 40px;
      }

      &-index {
        grid-area: index;
        font-style: italic;
        font-size: 20px;
      }

      &-action {
        grid-area: action;
        display: flex;
        justify-content: flex-end;

        p {
          transition: 0.3s;
          font-style: italic;
          font-weight: bold;
          font-size: 20px;
          margin: 0 8px 0 0;
        }

        img {
          height: 24px;
        }
      }

      &-content {
        grid-area: content;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &-title {
          font-size: 120px;
          line-height: 100px;
          margin: 0;
          font-style: italic;

          @media (max-width: 1200px) {
            font-size: 70px;
            line-height: 60px;
          }

          @media (max-width: 900px) {
            font-size: 60px;
            line-height: 50px;
          }
        }

        &-meta {
          font-style: italic;
          font-size: 20px;
          color: #ccc;

          @media (max-width: 900px) {
            font-size: 18px;
            margin: 15px 0;
          }
        }

        &-description {
          margin: 0;
          font-style: italic;
          font-size: 26px;
          font-weight: lighter;
          width: 60%;

          @media (max-width: 1200px) {
            width: 100%;
          }

          @media (max-width: 900px) {
            font-size: 22px;
          }
        }
      }
    }

    &--change {
      transition: 0.6s;
      transform: scale(0.6) translateY(-40%);
      opacity: 0;
    }

    &--changed {
      .slider-item-overlay {
        opacity: 1;
      }

      .slider-item-transition {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:hover {
      .inner-action {
        p {
          transform: translateX(-5px);
        }
      }
    }
  }

  @keyframes transition-in {
    from {
      opacity: 0;
      transform: translateY(45%) scale(0.6);
    }

    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
</style>
