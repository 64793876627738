<template>
  <div class="slider">
    <slider-item :index="index"
                 :size="slides.length"
                 :slide="active"
                 :classes="slideClasses"
                 @transit="$emit('transit', $event)"
    />
    <next-slide :url="next.cover" @change="initChange" :index="index" />
  </div>
</template>

<script>
import SliderItem from '@/components/home/slider/SliderItem'
import NextSlide from '@/components/home/slider/NextSlide'
import slides from '@/content/slides'

export default {
  name: 'index',
  components: {
    NextSlide,
    SliderItem
  },
  data () {
    return {
      slides,
      index: 0,
      slideClasses: 'slider-item--changed'
    }
  },
  created () {
    const index = Number(this.$route.params.index)

    if (index === undefined) {
      return
    }

    if (index - 1 > 0 && index - 1 < this.slides.length) {
      this.index = Number(index - 1)
    }
  },
  watch: {
    index (value) {
      let index = value + 1

      if (index === 1) {
        index = ''
      }
    }
  },
  computed: {
    active () {
      return this.slides[this.index]
    },
    next () {
      if (this.slides.length === this.index + 1) {
        return this.slides[0]
      } else {
        return this.slides[this.index + 1]
      }
    }
  },
  methods: {
    initChange () {
      this.slideClasses = 'slider-item--change'

      setTimeout(() => {
        this.finishChange()
      }, 600)
    },
    finishChange () {
      this.slideClasses = 'slider-item--changed'

      if (this.slides.length === this.index + 1) {
        this.index = 0
      } else {
        this.index++
      }
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.slider {
  padding: 60px;

  @media (max-width: 500px) {
    padding: 30px;
  }
}
</style>
