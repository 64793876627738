<template>
  <div class="title">
    <div class="title-item-wrapper">
      <p class="title-item title-sub text-shadow">Jonas Wanner</p>
    </div>
    <div class="title-item-wrapper">
      <h1 class="title-item title-main">Portfolio</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped="scoped">
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    animation: transition-in 600ms;
    animation-fill-mode: both;

    @media (max-width: 900px) {
      display: none;
    }

    &-item {
      margin: 0;

      &-wrapper {
        overflow: hidden;
      }
    }

    &-sub {
      font-size: 30px;
      font-style: italic;
      animation: transition-text-in 600ms;
      animation-delay: 600ms;
      animation-fill-mode: both;

      @media (max-width: 1200px) {
        font-size: 20px;
      }
    }

    &-main {
      font-size: 140px;
      font-style: italic;
      animation: transition-text-in 600ms;
      animation-delay: 200ms;
      animation-fill-mode: both;

      @media (max-width: 1200px) {
        font-size: 100px;
      }
    }
  }

  @keyframes transition-in {
    from {
      opacity: 0;
      transform: scale(0.7);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes transition-text-in {
    from {
      opacity: 0;
      transform: translateY(60%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
</style>
