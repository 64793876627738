export default [
  {
    title: 'Dodge',
    description: 'A day on the streets with the Dodge Challenger SRT',
    date: new Date('2020-11-26'),
    cover: '/images/slides/dodge/dodge-1-scaled.jpg',
    categories: ['Photo', 'Video'],
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minimiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    images: [
      {
        path: '/images/slides/dodge/dodge-5-scaled.jpg',
        title: 'Dodge Challenger SRT 8'
      },
      {
        path: '/images/slides/dodge/dodge-3-scaled.jpg',
        title: 'Dodge Challenger SRT 8'
      },
      {
        path: '/images/slides/dodge/dodge-4-scaled.jpg',
        title: 'Dodge Challenger SRT 8'
      },
      {
        path: '/images/slides/dodge/dodge-2-scaled.jpg',
        title: 'Dodge Challenger SRT 8'
      }
    ],
    video: 'https://www.youtube.com/embed/MjXD0dA6r5M'
  },
  {
    title: 'Val Grande',
    description: 'Five days in the wild',
    date: new Date('2020-10-03'),
    cover: '/images/slides/valgrande/valgrande-2-scaled.jpg',
    categories: ['Photo'],
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minimiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    images: [
      {
        path: '/images/slides/valgrande/valgrande-1-scaled.jpg',
        title: 'Val Grande'
      },
      {
        path: '/images/slides/valgrande/valgrande-3-scaled.jpg',
        title: 'Val Grande'
      },
      {
        path: '/images/slides/valgrande/valgrande-4-scaled.jpg',
        title: 'Val Grande'
      },
      {
        path: '/images/slides/valgrande/valgrande-5-scaled.jpg',
        title: 'Val Grande'
      },
      {
        path: '/images/slides/valgrande/valgrande-6-scaled.jpg',
        title: 'Val Grande'
      }
    ]
  }
]
