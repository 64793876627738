<template>
  <div class="copyright" @click="show = true">
    Impressum
  </div>
  <div v-if="show" class="information" @click="show = false">
    <div class="information-inner">
      <h2>Impressum</h2>
      <p>
        Diese Webseite wurde von und durch Jonas Wanner in dem Rahmen einer Schularbeit umgesetzt.
      </p>
      <p>
        Jonas Wanner<br>
        Harschwendistrasse 28<br>
        9104 Waldstatt<br>
        <a href="mailto:jonas@upscore.ch">jonas@upscore.ch</a><br>
      </p>
      <p>
        <br>
        <i>Copyright © {{ new Date().getFullYear() }}</i>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  data () {
    return {
      show: false
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 5px;
  margin: 60px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.2s;

  &:hover {
    background: #f3f3f3;
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.information {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000045;
  transition: 200ms;

  &-inner {
    max-width: 600px;
    background: white;
    box-shadow: 0 0 30px #00000054;
    padding: 20px 40px;
  }
}
</style>
